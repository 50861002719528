import { RawContact } from '@/types';
import { Paths, setValue } from './dataUtil';

export function createRawPersona(inputPersona: Record<string, any>, mapped: Record<string, string | string[]>): Partial<RawContact> {
	const rawContact: Partial<RawContact> = {};

	try {
		const get = (v: string): any => {
			try {
				const mappedValue = mapped[v] as string;
				if (v === 'Points' && typeof inputPersona[mappedValue] === 'string') {
					return inputPersona[mappedValue].replaceAll(',', '').trim();
				}
				const vv = inputPersona[mappedValue];
				if (typeof vv === 'string' && vv?.length < 1000) return vv.trim();
				return inputPersona[mappedValue];
			} catch (error) {
				throw new Error(`Error getting ${v} from persona: ${(error as Error)?.message}`);
			}
		};

		let first = get('First Name'),
			last = get('Last Name'),
			full = get('Full Name');

		if ((!first || !last) && full) {
			const name = full?.toString()?.split(' ');
			first = name[0];
			last = name[1];
		}

		const safeBool = (inp: any): boolean | null => (inp != null ? ['true', 'yes', '1', 'y', 't'].includes(inp.toString().toLowerCase().trim()) : null);
		const setIfNotEmpty = (key: Paths<RawContact>, value: any) => {
			if (value !== null && value !== undefined) {
				setValue(rawContact, key, value);
			}
		};

		setIfNotEmpty('firstName', first);
		setIfNotEmpty('lastName', last);
		setIfNotEmpty('srcID', get('Source Id'));
		setIfNotEmpty('mobilePhone', get('Cell Phone'));
		setIfNotEmpty('email', get('Email Address'));
		setIfNotEmpty('homePhone', get('Home Phone'));
		setIfNotEmpty('favoriteStore', get('Fav. Store'));
		setIfNotEmpty('loyalty', safeBool(get('Loyalty Status')));
		setIfNotEmpty('emailoptin', safeBool(get('Opt-in Email')));
		setIfNotEmpty('smsoptin', safeBool(get('Opt-in SMS')));
		setIfNotEmpty('smsoptout', safeBool(get('Explicit SMS Opt-out')));
		setIfNotEmpty('emailOptOut', safeBool(get('Opted Out Email')));
		setIfNotEmpty('medID', get('Medical ID'));
		setIfNotEmpty('medIDExpiration', get('Medical ID Expiration'));

		let ctype: string | number | undefined = get('Customer Type');
		if (ctype && isNaN(Number(ctype))) {
			ctype = typeof ctype === 'string' && ctype.toLowerCase().includes('med') ? 1 : 2;
		}
		setIfNotEmpty('customerType', ctype);
		setIfNotEmpty('overridesignupdate', get('Signup Override Date'));
		setIfNotEmpty('gender', get('Gender'));
		setIfNotEmpty('birthdate', get('Birthday'));
		setIfNotEmpty('loyaltySignupTS', get('Date Joined'));
		setIfNotEmpty('timezone', get('Timezone'));
		setIfNotEmpty('avatar', get('Avatar (Profile Pic)'));
		setIfNotEmpty('ip', get('IP'));
		setIfNotEmpty('twitter', get('Twitter'));
		setIfNotEmpty('website', get('Website'));
		setIfNotEmpty('ageGate', safeBool(get('Age Gate')));
		setIfNotEmpty('legacypoints', get('Points'));
		setIfNotEmpty('pointsDate', get('Points Date'));
		setIfNotEmpty('addr_city', get('City'));
		setIfNotEmpty('addr_country', get('Country'));
		setIfNotEmpty('addr_zipcode', get('Zipcode'));
		setIfNotEmpty('addr_state', get('State / Region'));
		setIfNotEmpty('addr_street', get('Street'));

		if (rawContact.loyaltySignupTS && rawContact.overrideLoyaltyTS) {
			// throw new Error('Contact cannot have both "Signup Override Date" and "Date Joined"');
			delete rawContact.loyaltySignupTS;
		}

		const customAttributes = mapped['Custom Attributes'];
		if (Array.isArray(customAttributes)) {
			customAttributes.forEach((header: string) => {
				const cattr = inputPersona[header];
				if (header && cattr) (rawContact as any)[`cattr_${header.replaceAll('cattr_', '')}`] = cattr;
			});
		}
	} catch (error) {
		const phoneOrEmail = rawContact?.mobilePhone || rawContact?.email;
		console.log('error creating rawContact', rawContact, error);
		throw new Error(`Error creating persona for ${phoneOrEmail}: ${(error as Error)?.message}`);
	}

	return rawContact;
}
